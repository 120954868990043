@import "./style/library";

body {
  margin: 0;
  //font-family: "Arial", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0; }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

  input[type=number] {
    -moz-appearance: textfield; } }


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none; }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

a, button {
  font-family: inherit;
  color: inherit;
  background: none;
  border: 0;
  padding: 0; }

button:focus {
  outline: none !important; }

::-webkit-scrollbar {
  width: 6px; }

/* Track * */
::-webkit-scrollbar-track {
  background: transparent; }

/* Handle * */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; }

/* Handle on hover * */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

