.column {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;

  .cell {
    width: 100%;
    display: grid;
    grid-template: 1fr 20px / 1fr;

    .cols {
      display: flex;
      grid-gap: 5px;
      align-items: flex-end;

      .col {
        width: 10px;
      }
    }

    .name {
      border-top: 3px solid #e2e1e1;
      font-size: 12px;
      font-weight: bold;
      padding: 5px 0 0;
      margin: 0;
    }
  }
}
