@import "../../../style/library";

.mainCont {
    padding: 50px 40px;

    .filterRow {
        width: 100%;
        box-shadow: 0 0 7px 0 #bfbebe;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .nameRow {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 25px;
            width: 90%;
            color: #707070;
            fill: #707070;
            box-shadow: 0 0 7px 0 #bfbebe;
            border-radius: 20px;
            cursor: pointer;
        }

        .delRow {
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 25px;
            width: 10%;
            color: #707070;
            fill: #707070;
            box-shadow: 0 0 7px 0 #bfbebe;
            border-radius: 20px;
            cursor: pointer;
        }

        .textWrap {
            width: 100%;
            border-radius: 5px;
            margin: 0;
            justify-content: center;
            text-align: center;
            align-self: center;
            padding: 6px 7px;
        }

        .text {
            padding: 1px 7px;
            margin: 0;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }
    }

    @media (min-width: 1200px) {
        max-width: 1500px;
        margin: 0 auto;
        padding: 50px 70px;
    }

    .withBorder {
        padding: 0 0 15px;
        border-bottom: 2px solid #e2e1e1;
        margin: 0 0 30px;
    }

    h2 {
        font-size: 24px;
        padding: 0 0 15px;
        border-bottom: 2px solid #e2e1e1;
        margin: 0 0 30px;
    }

    .cells {
        border-style: solid;
        border-color: lightgray;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 7px 0 #bfbebe;
        padding: 15px 30px;
        border-radius: 10px;

        .footer {
            border-top: 2px solid #e2e1e1;
            padding: 10px 0 0;

            button {
                box-shadow: 0 0 7px 0 #bfbebe;
                padding: 2px 15px;
                border-radius: 20px;
                color: #707070;
            }
        }
    }

    .cell_two_grid {
        @extend .cells;
        grid-column-start: 1;
        grid-column-end: 3;

    }

    .speechClimate {
        width: 100%;

        .titles {
            p {
                margin: 0;
            }
        }

        .flexEnd {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .circle {
            display: grid;
            grid-template: 1fr 1fr / 1fr 1fr;
            grid-gap: 5px;
            width: 100%;
            height: 300px;

            .red,
            .yellow,
            .blue,
            .green {
                height: 100%;
                width: 100%;
                display: flex;

                .transparent {
                    display: flex;

                    .cell {
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .red {
                .transparent {
                    height: 100px;
                    width: 100px;
                    align-self: flex-end;
                    align-items: flex-end;
                    border-radius: 0 100% 0 0;
                    background: rgba(255, 0, 0, 0.5);

                    .cell {
                        background: rgb(167, 3, 3);
                        border-radius: 0 100% 0 0;
                        height: 50%;
                        width: 50%;
                    }
                }
            }

            .yellow {
                justify-content: flex-end;

                .transparent {
                    height: 60px;
                    width: 60px;
                    align-self: flex-end;
                    align-items: flex-end;
                    justify-content: flex-end;
                    border-radius: 100% 0 0 0;
                    background: rgba(255, 142, 0, 0.5);

                    .cell {
                        background: rgb(255, 143, 0);
                        border-radius: 100% 0 0 0;
                        height: 80%;
                        width: 80%;
                    }
                }
            }

            .blue {
                justify-content: flex-end;

                .transparent {
                    height: 150px;
                    width: 150px;
                    justify-content: flex-end;
                    border-radius: 0 0 0 100%;
                    background: rgba(65, 92, 152, 0.5);

                    .cell {
                        background: rgb(65, 92, 152);
                        border-radius: 0 0 0 100%;
                        height: 80%;
                        width: 80%;
                    }
                }
            }

            .green {

                .transparent {
                    height: 120px;
                    width: 120px;
                    border-radius: 0 0 100% 0;
                    background: rgba(62, 170, 27, .5);

                    .cell {
                        background: rgb(62, 170, 27);
                        border-radius: 0 0 100% 0;
                        height: 70%;
                        width: 70%;
                    }
                }
            }
        }
    }

    .HIXCLIX {
        display: grid;
        grid-template: 1fr / 1fr 1fr;

        @media(max-width: 1268px) {
            grid-template: 1fr 1fr / 1fr;
        }
    }

    .SpeechTonality {
        width: 100%;
        display: grid;
        grid-template: 1fr / 1fr 2.3fr;
        grid-gap: 30px;

        @media(max-width: 1168px) {
            grid-template: 1fr 1fr / 1fr;
        }
    }

    .CSpeechTonality {
        width: 100%;
        display: grid;
        grid-template: auto auto / 1fr;
        grid-gap: 30px;
    }

    .tonality {
        width: 100%;

        h3 {
            font-size: 22px;
            margin: 0 0 20px;
        }
    }

    .words {
        margin: 30px 0 0;

        .words_main {
            display: grid;
            grid-template: 1fr / 1fr 1fr;

            @media(max-width: 1268px) {
                grid-template: 1fr auto / 1fr;
                grid-gap: 50px;

                .vocabulary {
                    border-right: none !important;
                    padding: 0 !important;
                    margin: 0 0 0 0 !important;
                }
            }

            .vocabulary {
                border-right: 3px solid #e2e1e1;
                padding: 0 30px 0 0;
                margin: 0 0 10px 0;
            }

            .distribution {
                width: 100%;
                padding: 2px 0 0 30px;
            }
        }
    }
}


.variants {

    .variantsCont {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        grid-gap: 15px;

        .variant {
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #707070;
            fill: #707070;
            box-shadow: 0 0 7px 0 #bfbebe;
            border-radius: 5px;
            cursor: pointer;

            .text {
                padding: 1px 7px;
                margin: 0;
                font-weight: bold;
                display: flex;
                align-items: center;
                font-size: 14px;
            }

            .lastBorder {
                border-radius: 0 20px 20px 0;
            }

            hr {
                margin: 0;
                background: #fff;
                width: 2px;
                height: 100%;
            }
        }
    }
}

.header {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 20px;
    // textAlign: 'center';
    color: grey;
    background-color: #415c98;
    color: white;
    // height: 25px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: #415c98;
    line-height: 8px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
}

.titleLine {
    border-top: 20px solid #415c98;
    float: right;
    margin-top: -6px;
    margin-left: 10px;
    flex-grow: 1;
}


.titleLineStart {
    border-top: 20px solid #415c98;
    // float: left;
    margin-top: -6px;
    margin-left: 1px;
    flex-grow: 0.02;
}

.info {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
}

.info::after {
    content: "\a";
    white-space: pre;
}

.img {
    height: 120px;
    margin-top: -20px
}

.AverageBar {
    // box-shadow: 10px 10px lightgray;
    border-style: solid;
    border-color: lightgray;
}