@import "../../../style/library";
.mainCont {
  padding: 46px 95px 40pc 104px;

  .content {
    width: 100%; }

  .breadcrumb {
    width: 100px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 18px;
    filter: $soft-drop-shadow; }

  .block {
    border-top: 2px solid;

    .descText {
      font-size: 18px;
      font-weight: bold;
      margin: 8px 0; }

    ul {
      list-style: none;

      .row {
        height: 90px;
        font-size: 18px;
        display: grid;
        grid-template: 1fr/ 1fr 1fr .5fr;
        align-items: center;
        border-radius: 10px;
        grid-gap: 10px;

        .title {
          font-weight: bold; }
        .change {
          height: 30px;
          border-radius: 18px;
          filter: $soft-drop-shadow; } } } }

  .wrapper {
    nav {
      ul {
        width: 320px;
        list-style: none;
        border-bottom: 1px solid;
        li {
          border-top: 1px solid;
          .link {
            font-size: 18px; }

          .activeLink {
            svg {
              path {
                fill: $color-primary-1000; } } } } } } } }

