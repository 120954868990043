.mainTagCloud {
  position: relative;
  width: 100%;
}

.app-outer {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  grid-gap: 80px;
  right: 0;
  top: 0;
}

.app-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1000px;
  width: 100%;
}

.app-inner  h3{
  font-weight: bold;
  font-size: 16px;
  padding: 0 0 5px;
  border-bottom: 2px solid;
}

.tag-cloud {
  flex: 1;
}

.tag-cloud > div {
  transition: 1.4s;
}
