.mainCont {
  display: grid;
  grid-template: auto 1fr / 1fr;
  margin: 0 30px 30px 0;
  border-radius: 10px;
  width: 100%;

  .firstRow {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    align-items: flex-start;
    margin-bottom: 20px;

    .breadcrumbs {
      padding: 0 15px 0 5px;
      display: flex;
      font-size: 14px;
    }

    .variants {

      .variantsCont {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        grid-gap: 15px;

        .variant {
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #707070;
          fill: #707070;
          box-shadow: 0 0 7px 0 #bfbebe;
          border-radius: 5px;
          cursor: pointer;

          .text {
            padding: 1px 7px;
            margin: 0;
            font-weight: bold;
            display: flex;
            align-items: center;
            font-size: 14px;
          }

          .lastBorder {
            border-radius: 0 20px 20px 0;
          }

          hr{
            margin: 0;
            background: #fff;
            width: 2px;
            height: 100%;
          }
        }
      }
    }
  }

  .secondRow {
    width: 100%;
    box-shadow: 0 0 7px 0 #bfbebe;
    background: #fff;
    border-radius: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 22px;
    }
  }
}
