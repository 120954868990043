.vocabulary {
  padding: 0;

  .head {
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      border-bottom: 0;
      padding: 0;
      margin: 0;
    }

    .wordsCloud {
      padding: 2px 7px;
      border-radius: 4px;
      font-weight: bold;
    }

    .select {
      width: 150px;
      border: none;
      outline: none;
    }
  }

  .vocMain {
    display: grid;
    grid-template: 1fr / 70% 30%;

    .legends {
      border-left: 3px solid #e2e1e1;
      margin-bottom: 20px;
      padding: 0 0 0 10px;

      h4 {
        font-size: 16px;
        color: #707070;
        border-bottom: 2px solid #e2e1e1;
        font-weight: bold;
        padding: 0 0 5px;
      }

      p {
        margin: 3px;
        font-weight: bold;
        font-size: 14px;
      }

      button {
        font-size: 14px;
      }
    }
  }
}