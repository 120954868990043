.mainCont {
  width: 600px;
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  outline: none;

  &.open {
    display: block; }

  .head {
    background: #B90000;
    border-radius: 8px 8px 0 0;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #FFFFFF; } }

  .descText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.027em;
    white-space: pre-line; } }
