.mainCont {
  width: 850px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;

  .head {
    border-radius: 8px 8px 0 0;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
    }
  }

  .descText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.027em;
  }

.error{
  font-size: 15px;
  // margin-top: 40px;
  // color: red;
  width: 400px;
  margin: 0 auto;
}

.inputfield{
    width : 80%;
    border-radius: 50;
    margin-right : 10px;
    right: 0;
    position : absolute;
}


input::placeholder{
    font-weight: bold;
    padding-left: 10px
}

}