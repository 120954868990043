@import "../../style/library";

.description {
  width: 550px;
  padding: 0 0 0 0;
  margin: 0 0 0 0; }

.termstext {
  padding: 30px 0 0 0;
  margin: 0 0 0 0;
  position: relative;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: .3s ease-in-out;

  .terms {
    overflow-y: scroll;
    width: 550px;
    padding: 20px 20px 20px 20px;
    margin: 0 0 0 0; }

  .submitCont {
    display: flex;
    justify-content: center; }


  .submit {
      height: 45px;
      background: #3EAA1B;
      text-align: start;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      grid-gap: 10px;
      padding: 10px 8px;
      color: white;

      img {
        width: 33px;
        height: 33px;
        object-fit: contain; } } }


.form {
  padding: 30px 0 0 0;
  margin: 0 0 0 0;
  position: relative;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: .3s ease-in-out;


  .fields {
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 20px 20px 20px 20px;

    .password {
      margin: 9px 0 0;
      padding: 0 0 9px;
      text-align: end;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer; }

    .fieldsActions {
      .formControls {
        margin: 50px 0 9px;
        display: grid;
        grid-template: 1fr / 1fr 1fr;
        grid-column-gap: 10px;
        padding: 0 0 17px;

        .formControl {
          display: flex;
          align-items: center;

          .name {
            margin-right: 15px;
            font-weight: bold; } } }

      .news {
        margin: 15px 0 0;
        padding: 10px;
        border-top: 1px solid;

        p {
          line-height: 19px; } }

      .checkboxCont {
        display: flex;

        .checkboxIcon {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0; }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            border: 1px solid;
            border-radius: 3px;

            div {
              position: absolute;
              display: none;
              left: 4px;
              transition: .2s ease;
              top: 1px;
              width: 8px;
              height: 10px;
              border: solid;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg); } }

          input:checked + .checkmark {
            div {
              display: block; } } } } } }

  .submitCont {
    display: flex;
    justify-content: center;

    .submitText {
      font-size: 17px; }

    .submit {
      height: 45px;
      background: #3EAA1B;
      text-align: start;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      grid-gap: 10px;
      padding: 10px 8px;
      color: white;

      img {
        width: 33px;
        height: 33px;
        object-fit: contain; } }

    .submitLogout {
      height: 45px;
      background: #aa991b;
      text-align: start;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      grid-gap: 10px;
      padding: 10px 8px;
      color: white;

      img {
        width: 33px;
        height: 33px;
        object-fit: contain; } } } }

.input2FA {
  margin: 5px;
  border: #1a0500 solid 1px;
  width: 60%; }
