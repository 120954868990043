.mainCont {
  height: 100%;
  display: grid;
  grid-template: auto 319px / 100%;
  border-radius: 10px;

  .head {
    display: grid;
    grid-template: 1fr / 30% 25% 27% 13%;
    padding: 0 8px 0 0;
    border-radius: 3px 3px 0 0;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      height: 100%;
      padding: 2px 5px;
      cursor: pointer;
    }
  }

  .rows {
    display: grid;
    grid-template: auto / 1fr;
    overflow: auto;

    .row {
      display: grid;
      grid-template: 1fr / 35% 25% 27% 13%;

      p {
        margin: 0;
        font-size: 14px;
        padding: 2px 5px;
        font-weight: 500;

        &:nth-child(3) {
          padding: 2px 0 0 15px;
        }
      }
    }
  }
}