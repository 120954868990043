.title {
  font-weight: bold;
  font-size: 48px;
  line-height: 55px; }

.subTitle {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 27px;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px; } }

.saveChangesBtn {
  display: flex;
  justify-content: flex-end; }

.mainCont {
  border-radius: 6px;
  padding: 50px 40px 0;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px 0; }

  .tabs {
    height: 50px;
    border-bottom: 2px solid #C6C2DE;

    .tab {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      transform: translateY(2px);
      border-bottom: 3px solid transparent;
      outline: none;
      font-weight: 500; }
    .activeTab {
      border-bottom: 3px solid #25213B; } } }

.borderBottom {
  border-radius: 0 0 6px 6px; }

.borderTop {
  border-radius: 6px 6px 0 0; }
