.main {
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;

  .mass {
    padding: 3px;
    background-color: #cee0ff;
    color: #626262;
    display : flex;
    align-items: center;
    justify-content: right;
    border-radius: 5px; }

  .element {
    margin-bottom: 10px;
    color: #626262;
    gap: 2px;
    align-items: center;
    display : flex; }

  .children {
    margin-left: 20px;
    display : block; }

  .manipulate {
    align-items: center;
    justify-content: right;
    display : flex;
    cursor: pointer; }

  .opener {
    cursor: pointer; }

  .button {
    cursor: pointer; }

  .name {
    cursor: pointer;
    width: 100%; }

  .rootName {
    margin-left: 5px;
    cursor: pointer;
    width: 100%; }

  .selected {
    color: #415c98; } }

.center {
  position: absolute;
  z-index: 10001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
