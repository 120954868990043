.mainCont {

  .breadcrumb {
    font-weight: bold;
    margin-bottom: 119px; }


  ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;

    li {

      .link {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;

        p {
          display: flex;
          align-items: center;
          height: 48px;
          padding-left: 31px; } }

      .link:focus {
        color: blue;

        p {
          background: #C5DCFA;
          border-radius: 12px; } } } } }


