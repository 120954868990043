@import "../../../../style/library";
.mainCont {
  width: 420px;
  position: relative;
  height: 845px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  @media(max-width: 1100px) {
    width: 325px; }

  .selectCont {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;

    .loader {
      height: 160px;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      background: none;
      border: none;
      justify-content: center;

      &.active {
        background: #43AB1B !important;
        border-radius: 10px;

        .figure {
          .circle {
            background: #fff; }

          .center {
            background: #43AB1B !important;

            .center_circle {
              background: #fff; } }

          .top {
            background: #43AB1B !important; } } }

      &:hover {
        background: #E9FAE5;

        &.active {
          background: #34bf00 !important;

          .figure {
            .center {
              background: #34bf00 !important; }

            .top {
              background: #34bf00 !important; } }

          .center {
            background: #E9FAE5; }

          .top {
            background: #E9FAE5; } } }


      span {
        &:first-child {
          height: 100%;
          flex-direction: column; } }

      .figure {
        height: 88px;
        width: 88px;
        border-radius: 100%;
        position: relative;

        .circle {
          height: 88px;
          width: 88px;
          border-radius: 100%;
          position: absolute;
          background: #43AB1B;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          &.active {
            background: #fff; } }

        .top {
          position: absolute;
          top: -1px;
          left: 50%;
          transform: translateX(-50%);
          width: 11px;
          border-radius: 3% 3% 0 0;
          height: 50px;
          background: #fff; }

        .center {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 45px;
          height: 45px;
          border-radius: 100%;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          .center_circle {
            width: 27px;
            height: 27px;
            background: #43AB1B;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 50%; } } }

      h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 5px 0 0;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        color: #43AB1B;

        &.activeText {
          color: #fff !important; } } } }


  .language {
    border-radius: 10px;
    padding: 2px 10px;

    .headWithSwitch {

      .title {
        font-size: 18px; }

      .subtitle {
        font-size: 16px; } }

    .speechChange {
      display: flex;
      justify-content: space-between;
      align-items: center;
 }      // border-top: 1px solid #bfbfbf

    .textTypeChange {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    h3 {
      font-size: 18px;
      font-weight: bold; }

    .slider {
      border-bottom: 1px solid #bfbfbf;
      margin-bottom: -22px;
      .sliderCont {
        display: grid;
        grid-template: 30px / 80px 1fr 80px;

        p {
          color: #707070;
          font-size: 16px; }

        .thumps {
          height: 10px;
          width: 10px;
          border-radius: 0;
          background: #0d47a1;
          display: flex;

          span {
            height: 5px;
            width: 1px;
            background: #fff; } } } } }

  .news {
    border-radius: 10px;
    padding: 10px;

    .textNewsTitle {
      font-size: 18px;
      font-weight: bold; }

    .textNews {

      .info {
        .info_head {
          align-items: center;

          .info_title {
            font-size: 18px;
            font-weight: bold; }

          img {
            margin-left: auto; } }

        .info_count_text {
          overflow: auto;
          font-size: 14px; } }

      .instruction {

        h3 {
          margin: 4px 0 0;
          font-size: 18px;
          font-weight: bold; }

        .group {
          .title {
            display: flex;
            align-items: center;
            margin: 0;

            .iconX {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              border-radius: 50px; }

            p {
              font-size: 18px;
              line-height: 18px;
              margin: 9px 0 0; } }

          .group_text_red {
            font-size: 16px;
            margin: 0; }

          .group_text_green {
            font-size: 16px;
            margin: 0; } } } } } }

