.autoFill {
  height: 56px;
  padding: 0 30px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px; } }

.fileUpload {
  display: flex;
  align-items: center;

  label {
    border-radius: 4px;
    font-weight: bold;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

  img {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 3px; }

  .progress {
    justify-content: flex-start;
    border-radius: 20px;
    align-items: center;
    display: flex;
    height: 30px;
    width: 40px; }

  .progressValue {
    animation: load 1s normal forwards;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(254, 255, 0, 1) 0%, rgba(255, 136, 0, 1) 50%, rgba(255, 20, 20, 1) 100%);
    height: 10px;
    width: 0; }

  @keyframes load {
    0% {
      width: 0; }
    100% {
      width: 100%; } } }


.modalSelectWithTextField {
  top: 57px;
  right: 0;
  left: 0;
  grid-template-columns: 1fr;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 5px 5px 1px #ddd;
  z-index: 10;

  li {
    border-radius: 6px;
    cursor: pointer; }

  .border {
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    margin: 0 10px; }

  li:hover {
    background: #ddd; } }


