@keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.lds-double-ring {
  position: relative;
}
.lds-double-ring div {
  box-sizing: border-box;
}
.lds-double-ring > div {
  position: absolute;
  width: 168px;
  height: 168px;
  top: 16px;
  left: 16px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #195081 transparent #195081 transparent;
  -webkit-animation: lds-double-ring 1s linear infinite;
  animation: lds-double-ring 1s linear infinite;
}
.lds-double-ring > div:nth-child(2),
.lds-double-ring > div:nth-child(4) {
  width: 148px;
  height: 148px;
  top: 26px;
  left: 26px;
  -webkit-animation: lds-double-ring_reverse 1s linear infinite;
  animation: lds-double-ring_reverse 1s linear infinite;
}
.lds-double-ring > div:nth-child(2) {
  border-color: transparent #9d9d9c transparent #9d9d9c;
}
.lds-double-ring > div:nth-child(3) {
  border-color: transparent;
}
.lds-double-ring > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.lds-double-ring > div:nth-child(3) div:before,
.lds-double-ring > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 72px;
  background: #195081;
  border-radius: 50%;
  box-shadow: 0 160px 0 0 #195081;
}
.lds-double-ring > div:nth-child(3) div:after {
  left: -8px;
  top: 72px;
  box-shadow: 160px 0 0 0 #195081;
}
.lds-double-ring > div:nth-child(4) {
  border-color: transparent;
}
.lds-double-ring > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.lds-double-ring > div:nth-child(4) div:before,
.lds-double-ring > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 62px;
  background: #9d9d9c;
  border-radius: 50%;
  box-shadow: 0 140px 0 0 #9d9d9c;
}
.lds-double-ring > div:nth-child(4) div:after {
  left: -8px;
  top: 62px;
  box-shadow: 140px 0 0 0 #9d9d9c;
}
.lds-double-ring {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
.lds-css {
  z-index: 1000;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-spinner {
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(255,255,255); /* Fallback color */
  background-color: rgba(255,255,255,0.7); /* Black w/ opacity */
}