@import "../../../../style/library";
.mainCont {
  grid-gap: 12px;

  .firstPart, .secondPart {
    width: 100%;
    background: #f8f8f8;
    filter: $soft-drop-shadow;
    border-radius: 10px;
    padding: 10px 22px 22px;
    display: grid;
    grid-template: 18px 1fr / 1fr;
    grid-gap: 15px;

    .table {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .row {
        display: grid;
        grid-template: 1fr / .7fr 1fr;
        grid-gap: 5px;
        align-items: center;
        @media(max-width: 1200px) {
          grid-template: 1fr / .3fr 1fr;
          padding: 0 10%; }
        @media(max-width: 1025px) {
          grid-template: 1fr 1fr / 1fr;
          padding: 0 1%; } }

      .row_title {
        font-size: 14px;
        line-height: 16px; }

      .bg {
        position: relative;
        width: 100%;
        height: 5px;
        border-radius: 5px; }

      .ranger {
        height: 100%;
        border-radius: 5px; } } }

  .secondPart {
    .table {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly; }

    .whiteStick {
      position: absolute;
      height: 8px; } }

  .firstPart {

    .whiteStick {
      position: absolute;
      height: 8px; } }

  .title {
    font-size: 14px;
    font-weight: bolder;
    text-align: center; } }
