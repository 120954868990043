@import "../../../style/library";

.AverageBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 7px 0 #bfbebe;
  border-radius: 10px;
  padding: 15px 30px;
  text-align: center;

  .header {
    font-size: 30px;
  }

  .footer {
    //border-top: 2px solid #e2e1e1;
    padding: 10px 0 0;
    text-align: center;
    height: 40px;
  }

  button {
    box-shadow: 0 0 7px 0 #bfbebe;
    padding: 2px 15px;
    border-radius: 20px;
    color: #707070;
  }

  .main_div {
    font-size: 24px;
  }

  .res1 {
    color: #efa90b;
    font-size: 24px;
  }

  .res0 {
    color: #0056b3;
    font-size: 24px;
  }
}



.RadialBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 7px 0 #bfbebe;
  border-radius: 10px;
  padding: 15px 30px;

  .footer {
    border-top: 2px solid #e2e1e1;
    padding: 10px 0 0;

    button {
      box-shadow: 0 0 7px 0 #bfbebe;
      padding: 2px 15px;
      border-radius: 20px;
      color: #707070;
    }
  }

  .main {
    display: grid;
    grid-template: 1fr / 300px 1fr;

    .sideTextHIX {
      transform: rotate(-90deg);
      font-size: 100px;
      color: gray;
      float: right;
      position: relative;
    }

    .sideTextCLIX {
      transform: rotate(-90deg);
      font-size: 100px;
      color: gray;
      // margin-left: 470px;
      // margin-top: -10px;
      position: relative;
      float: right;
    }

    .sideTextFLESCH {
      transform: rotate(-90deg);
      font-size: 60px;
      color: gray;
      position: relative;
      float: right;
    }

    .chartCont {
      width: 300px;
      height: 330px;
      justify-content: center;
      display: flex;

      .chart {
        .bars {
          height: 190px;
          width: 235px;
          position: relative;
          display: flex;
          flex-direction: column;
          border-radius: 10px;
          align-items: center;
          margin: 40px 0 0;

          svg {
            circle {
              &:nth-child(1) {
                filter: $soft-drop-shadow;
              }
            }
          }

          .bar_title {
            margin: 10px 0 10px;
            font-weight: bold;
            font-size: 14px;
          }

          .progressDesc {
            width: 145px;
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .title {
            font-size: 16px;
            line-height: 18px;
            font-weight: bolder;
            margin: 0;
          }

          .percentage {
            font-weight: bolder;
            font-size: 24px;
          }

          .maxSize {
            width: 100%;
          }

          .zielwert {
            font-size: 14px;
            line-height: 16px;
          }

          .zielwertNum {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      .targetVal {
        position: absolute;
        top: 200px;
        left: 160px;
      }
    }

    .desc {
      justify-self: center;
      max-width: 500px;

      p {
        font-size: 18px;
      }
    }
  }
}

.VColoredLinearChart {
  display: grid;
  grid-template: auto / 1fr;
  border-top: 2px solid #e2e1e1;

  .row {
    display: grid;
    grid-template: 1fr / 35% 65%;
    padding: 15px 0;
    border-bottom: 2px solid #e2e1e1;
    align-items: center;

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .coloredCells {
      position: relative;
      height: 15px;
      width: 100%;
      display: grid;
      // grid-template: 1fr / repeat(5, 1fr);
      overflow: hidden;
      border-radius: 30px;

      .coloredCell {
        height: 100%;
        width: 100%;
      }

      .arrow {
        height: 15px;
        width: 15px;
        position: absolute;
        transform: rotate(45deg);
        bottom: -7px;
        border: 1px solid #000;
      }
    }
  }
}


.circleCont {

  .titles {
    p {
      margin: 0;
      font-size: 20px;
    }
  }

  .flexEnd {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .circle {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-gap: 5px;
    width: 300px;
    height: 300px;
    background: #e2e1e1;
    border-radius: 100%;
    margin: 0 auto;

    .red,
    .yellow,
    .blue,
    .green {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;

      .transparent {
        position: absolute;
        display: flex;
      }

      .cell {
        position: absolute;
      }
    }

    .yellow {
      .transparent {
        height: 100px;
        width: 100px;
        align-self: flex-end;
        align-items: flex-end;
        border-radius: 0 100% 0 0;
        background: rgb(250, 191, 120);
        bottom: 0;
        left: 0;
      }

      .cell {
        background: rgba(255, 143, 0, .8);
        border-radius: 0 100% 0 0;
        height: 50%;
        width: 50%;
        bottom: 0;
        left: 0;
      }
    }

    .green {
      justify-content: flex-end;

      .transparent {
        height: 30%;
        width: 30%;
        align-self: flex-end;
        align-items: flex-end;
        justify-content: flex-end;
        border-radius: 100% 0 0 0;
        background: rgba(62, 170, 27, .5);
        bottom: 0;
        right: 0;
        z-index: 1;
      }

      .cell {
        background: rgb(62, 170, 27);

        border-radius: 100% 0 0 0;
        height: 60%;
        width: 60%;
        bottom: 0;
        right: 0;
      }
    }

    .blue {
      justify-content: flex-end;

      .transparent {
        height: 90%;
        width: 90%;
        justify-content: flex-end;
        border-radius: 0 0 0 100%;
        background: rgba(65, 92, 152, 0.5);
        right: 0;
      }

      .cell {
        background: rgb(65, 92, 152);
        border-radius: 0 0 0 100%;
        height: 80%;
        width: 80%;
        right: 0;
      }
    }

    .red {
      .transparent {
        height: 60%;
        width: 60%;
        border-radius: 0 0 100% 0;
        background: rgba(255, 0, 0, 0.5);
        left: 0;
      }

      .cell {
        background: rgb(167, 3, 3);

        border-radius: 0 0 100% 0;
        height: 50%;
        width: 50%;
        left: 0;
      }
    }
  }
}

.lineChart {
  .main {
    display: flex;

    .colors {
      display: flex;
      flex-direction: column-reverse;
      padding: 30px 0 43px;

      div {
        height: 100%;
        width: 10px;
      }
    }
  }
}

.comparisonDashDoubledChard,
.coloredMultiLinear {
  .head {
    display: flex;
    align-items: center;
    grid-gap: 30px;

    button {
      font-size: 14px;
    }

    .chartType {
      margin: 0 0 0 auto;

      button {
        min-width: 33px;

        svg {
          height: 28px;
        }
      }
    }

    .timeIntervals {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      button {
        min-width: 33px;
      }
    }
  }

  .charts {
    display: flex;

    .colors {
      display: flex;
      flex-direction: column-reverse;
      padding: 30px 0 43px;

      div {
        height: 100%;
        width: 10px;
      }
    }
  }
}

.coloredDistribution {

  .footer {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    padding-bottom: 20px;

    h6 {
      font-size: 17px;
      font-weight: bold;
      width: 140px;
    }

    p {
      font-size: 14px;
    }

    button {
      font-size: 12px;
    }
  }
}

.withoutBorder {
  border: none !important;
}

.withBorder {
  border-bottom: 2px solid #e2e1e1;
}

.textTitles {
  font-weight: bold;
  font-size: 16px;
  grid-gap: 10px;
}

.fullName {
  margin: 10px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: gray
}