.gear-black {
    position: relative;
    top: 35px;
    left: -20px;
    -webkit-animation: gear-direct 2s linear infinite;
    animation: gear-direct 2s linear infinite;
}

.gear-gray {
    position: relative;
    top: -10px;
    -webkit-animation: gear-reverse 2s linear infinite;
    animation: gear-reverse 2s linear infinite;
}

.gear-blue {
    position: relative;
    top: 10px;
    left: 23px;
    rotate: -4grad;
    -webkit-animation: gear-direct 2s linear infinite;
    animation: gear-direct 2s linear infinite;
}

.bot {
    position: relative;
    top: -15px;
}

.text {
    margin-top: -10px;
    color: #115293;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: bold;
    width: 200px;
}

@keyframes gear-direct {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes gear-direct {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes gear-reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes gear-reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.lds-css {
    z-index: 1000;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal-spinner {
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.7); /* Black w/ opacity */
}