@import "../../../style/library";
.select {
  position: absolute;
  top: 94px;
  right: 50px;
  border: none;
  filter: $middle-drop-shadow;
  padding: 0 12px 30px;
  z-index: 1;
  transition: .2s ease-in-out;
  .options {
    padding: 11px 0;
    text-decoration: none;
    border-bottom: 1px solid;
    .close {
      width: 20px; }
    img {
      height: 46px;
      width: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 21px 0 5px; }
    p {
      width: 266px;
      font-size: 16px; }

    .arrowRight {
      margin-left: auto; } }
  .triangle {
    position: absolute;
    right: 77px;
    top: -13px; } }

