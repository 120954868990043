@import "../../../style/library";
.mainCont {
  position: relative;



  .textEditor {
    min-height: 705px;
    margin-top: 12px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.161);

    .text {
      font-size: 30px;
      font-weight: 400; } } }

.modalMainCont {
  width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  outline: none;

  &.open {
    display: block; }

  .head {
    border-radius: 8px 8px 0 0;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      margin: 0;
      color: #FFFFFF; } }

  .descText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.027em; }
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ccc;

    li {
      width: 33.3333%;
      text-align: center;
      font-weight: 600;

      a {
        color: #202020;
        border-radius: 0 !important; } } } }

.batchProcessingProgressbar {
  width: 250px;
  cursor: pointer;

  .title, .errorTitle {
    font-weight: bold;
    display: block;
    font-size: 14px;
    margin-bottom: 8px; }

  .title {
    color: #43AB1B; }

  .errorTitle {
    color: #AB431B; }

  .bar {
    width: 100%;
    height: 6px;
    position: relative; }

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #cccccc; }

  .front, .errorFront {
    position: absolute;
    height: 100%; }

  .front {
    background-color: #43AB1B; }

  .errorFront {
    background-color: #AB431B; } }

.mainCont {
  width: 600px;
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  outline: none;

  &.open {
    display: block; }

  .head {
    background: rgb(63, 81, 181);
    border-radius: 8px 8px 0 0;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #FFFFFF; } }

  .descText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.027em;
    white-space: pre-line; } }

.detailsButton {
  height: 42px;
  font-weight: bold;
  cursor: pointer; }

.documentList {
  max-height: 75vh;
  overflow-y: auto;
  margin-bottom: 20px; }

.cellName, .cellIcon {
  height: 72px; }

.errorCall {
  color: #AB431B; }

.cellName {
  padding: 8px 0;
  margin-left: 10px;
  border-bottom: 1px solid #ccc;
  max-width: 524px;
  overflow: hidden;
  text-overflow: ellipsis; }

.cellIcon {
  padding: 6px 0;
  margin-right: 10px;
  border-bottom: 1px solid #ccc; }

.iconOpen {
  color: #ccc; }

.iconRunning {
  color: rgb(63, 81, 181);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.iconPaused {
  color: rgb(63, 81, 181); }

.iconDone {
  color: #43AB1B; }

.noPadding {
  padding: 0 !important; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.file {
  overflow-y: scroll;
  height: 300px;
  min-width: 300px;
  top: 130px;
  background-color: #ffffff;
  padding: 5px;
  z-index: 10;
  position: absolute;
  border: 1px solid #000000; }
