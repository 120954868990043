.mainCont {
  padding: 50px 40px;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px;
  }

  h2 {
    font-size: 24px;
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
  }

  .cells {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 7px 0 #bfbebe;
    border-radius: 10px;
    padding: 15px 30px;
    margin-right: 30px;

    .footer {
      border-top: 2px solid #e2e1e1;
      padding: 10px 0 0;

      button {
        box-shadow: 0 0 7px 0 #bfbebe;
        padding: 2px 15px;
        border-radius: 20px;
        color: #707070;
      }
    }

    .nameRow {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 100%;
      color: #707070;
      fill: #707070;
      box-shadow: 0 0 7px 0 #bfbebe;
      border-radius: 20px;
      cursor: pointer;
    }

    .delRow {
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 10%;
      color: #707070;
      fill: #707070;
      box-shadow: 0 0 7px 0 #bfbebe;
      border-radius: 20px;
      cursor: pointer;
    }

    .textWrap {
      width: 100%;
      border-radius: 5px;
      margin: 0;
      justify-content: center;
      text-align: center;
      align-self: center;
      padding: 6px 7px;
    }

    .text {
      padding: 1px 7px;
      margin: 0;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }


  }

  .sentenceConst {
    .preloaderCont {
      min-height: 440px;
      .mainDesc {
        display: grid;
        grid-template: 1fr / 1.3fr 1fr;
        @media(max-width: 968px) {
          grid-template: 1fr / 1.8fr 1fr;
        }
      }

      .withLinearCont {
        display: grid;
        grid-template: 1.7fr 1fr / 1fr;

        .desc {
          text-align: left;
          margin: 0;
        }
      }

      h3 {
        font-size: 20px;
        margin: 0;
        padding: 10px 0 0;
        font-weight: bold;
      }

      .loader {
        position: relative;
        transform: translateX(-60px);
        display: flex;

        .loaderValue {
          position: absolute;
          top: 80px;
          left: 50%;
          font-size: 50px;
          font-weight: bolder;
          transform: translateX(-50%);
          margin: 0;
        }

        .lDesc {
          min-width: 280px;
          margin: 60px 0 0 0;

          .lTitle {
            border: 0;
            font-size: 20px;
            margin: 0;
          }

          .lCount {
            font-size: 34px;
            line-height: 30px;
            margin: 0 0 10px;
          }

          .lText {
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .desc {
        text-align: left;
        margin: 0 0 0 0;

        @media(max-width: 968px) {
          width: 100%;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          margin: 0 0 10px;
        }

        p {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .moreLongerWords {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 0 10px;
        border-bottom: 2px solid #e2e1e1;

        p {
          margin: 0;
        }
      }

      .main {

        .mainHead {
          display: flex;
          align-items: center;
          grid-gap: 10px;
          margin-top: 20px;
        }

        .table {
          margin-top: 35px;
          padding: 0 0 30px;

          .thead {
            p {
              font-weight: bold;
            }
          }

          .thead, .tcell {
            height: 50px;
            display: grid;
            grid-template: auto / 50% 30% 20%;
            grid-gap: 1%;
            align-items: center;
            padding: 0 0 0 20px;
            border-bottom: 1px solid #e2e1e1;

            p {
              margin: 0;
            }
          }

          .tFoot {
            display: flex;
            justify-content: space-between;
            margin: 30px 0 0;
            padding: 20px 0 10px;
            align-items: center;
            border-bottom: 4px solid #e2e1e1;

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .charts {
    display: grid;
    grid-template: 1fr / repeat(3, 1fr);
    grid-gap: 40px;
    margin-top: 30px;
    @media(max-width: 968px) {
      grid-template: repeat(3, 1fr) / 1fr;
    }
  }
}

.textTitles {
  font-weight: bold;
  font-size: 16px;
  grid-gap: 10px;
}
