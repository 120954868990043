.saveChangesBtn {
  display: flex;
  justify-content: flex-end;

  button {
    font-weight: bold;
    font-size: 14px; } }

.main_title {
  font-weight: bold;
  font-size: 48px;
  line-height: 55px; }

.mainCont {
  min-height: 1400px;
  border-radius: 6px;
  padding: 50px 40px 0;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px 0; }

  &:first-child {
    border-radius: 6px; }

  .title {
    height: 56px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding: 0 27px;
    margin: 0; }

  .featuresSwitchs {
    padding: 0 20px 10px; }

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    padding: 0 30px 10px;

    li {
      display: flex;
      padding: 10px 0;

      .title {
        width: 221px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400; }

      ul {
        display: flex;
        flex-direction: column;
        align-self: start; } } }

  //.autofillGroups
  //  top: 128px

  .fieldsUser, .autofillBlock {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 50px;

    .userRightsList {
      display: flex;

      .userRightsTitle {
        font-size: 20px;
        line-height: 16px;
        margin-right: 20px; }

      ul {
        padding: 0;
        height: 330px;

        li {
          padding: 0;

          label {
            margin: 0 !important; } } } } } }

.borderBottom {
  border-radius: 0 0 6px 6px; }

.borderTop {
  border-radius: 6px 6px 0 0; }

.autofillBlock {
  width: 100%;
  top: 56px; }
