.selectRow {
  height: 60px; }

.export {
  cursor: pointer;
  border-radius: 4px;
  font-size: 30px !important;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-weight: bold; }



