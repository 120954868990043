@import "../../style/library";

.blockInternal {
  border-top: 2px solid;

  .descText {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0; }

  .input2FA {
    border: #1a0500 solid 1px;
    width: 100%; } }


