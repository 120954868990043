.mainCont {
  border-radius: 10px;
  padding: 10px 50px;

  .tableWrapper {

    .tableWrapper__head {
      padding: 10px 10px;
      border-radius: 6px 6px 0 0;
      background: rgba(88, 88, 88, 1);
      color: #fff; }

    .fileSelector {
      margin-top: 0px;
      padding: 0px 00px;
      border-radius: 6px 6px 0 0;
      color: #fff;

      h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px; } }

    .tableWrapper__head__filter {
      background: #fff;

      label {
        font-size: 14px;
        line-height: 16px;
        margin: 2px 0 0; } } } }


.selectionModal {
  .selectionModal__head {
    background: #D8D8D8;
    border-radius: 8px 8px 0 0; }


  .tabs {
    background: #EDEDED;

    ul {
      display: flex;
      flex-wrap: nowrap;

      li {
        cursor: pointer;
        width: 100%;

        a {
          height: 47px;
          border-radius: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: -0.015em;
          color: #333333; } } } } }


.table {
  width: 100%;
  background: #fff;
  border: none;
  border-collapse: collapse;

  td, th {
    border: none;
    text-align: left;
    padding: 8px; }


  th {
    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #25213B; } }


  td {
    p {
      font-size: 16px;
      line-height: 19px;
      color: #25213B; } } }


.contWrapper {
  height: auto;
  width: 100%;
  border-radius: 6px; }


.mediaHandler {
  @media (max-width: 1200px) {
    flex-direction: column; } }



