@import "../../../../../style/library";
.mainCont {
  width: 100%;

  table {
    .tableHead {
      min-height: 80px;

      p {
        font-size: 16px; } }

    .tableFilter {
      .pagination {
        width: 400px;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-left: auto;

        input {
          width: 38px;
          text-align: center;
          border: none; } }

      .perPage {
        //width: 200px
        border-radius: 4px; }

      p {
        font-size: 16px; }

      .filterBtn {
        height: 42px;
        font-weight: bold; }

      .addBtn {
        margin-left: auto;

        button {
          height: 42px;
          font-weight: bold; } }

      .search {
        width: 222px;
        border: 1px solid;
        border-radius: 4px; } }

    .filterDashboard {
      font-size: 16px;

      .filterRow {
        border-bottom: 1px solid;

        p, button {
          font-size: 16px;
          border-radius: 20px;
          font-weight: bold; }

        button {
          filter: $soft-drop-shadow; } }

      .secondRow {
        border-bottom: 1px solid;

        .order {
          width: 197px;
          height: 30px;
          border: 1px solid;
          font-size: 16px;
          border-radius: 5px;
          cursor: pointer; }

        input {
          height: 30px;
          width: 124px;
          border: none;
          filter: $soft-drop-shadow; }

        .minusIcon {
          margin-left: auto; } }

      .confirmation {
        button {
          filter: $soft-drop-shadow;
          border-radius: 17px;
          font-size: 16px;
          font-weight: bold; } } }


    .search {
      width: 220px; }

    .cell {
      font-size: 16px;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%; }

      div {
        font-size: 16px; }

      .title {
        font-size: 16px; }

      .versionStepper {
        border: 1px solid;
        border-radius: 5px; } }

    .row {

      .activeBlock {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        margin-left: auto; }

      .iconBtn {
        width: 35px;
        height: 35px;
        border-radius: 3px; } }

    .accordion {
      .group {
        display: grid;
        grid-template-columns: 35% 65%;

        .groupData {
          margin: 10px 0 20px;

          h4 {
            font-weight: bold;
            font-size: 16px; }

          ul {
            width: 100%;
            display: grid;
            grid-row-gap: 17px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              padding: 0;

              .title {
                font-size: 10px;
                line-height: 14px;
                font-weight: bold; }

              .text {
                font-size: 12px;
                line-height: 14px; }

              .active {
                color: #007F00; }

              .circle {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #707070; }

              .activeCircle {
                background: #007F00; } } } } }

      .groupData_ul1 {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0;
        grid-column-gap: 10px; }

      .groupData_ul2 {
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 15px; } }


    .selectRow {
      height: 60px;

      p {
        font-size: 16px;
        line-height: 19px; }

      .export {
        height: 35px;
        width: 150px;
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        font-weight: bold;
        margin-left: auto; }

      .delete {
        width: 35px;
        height: 35px;
        border-radius: 3px; } } }

  .customDatePicker {
    background: rgba(0, 0, 0, 0.09);
    height: 56px;
    padding: 6px 16px;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid; } }
