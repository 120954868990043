@import "../../../../../style/library";

.cell {
  cursor: pointer;
  font-size: 16px; }

.id {

  color: #415C98 !important;
  text-align: center !important;
  cursor: pointer;
  font-size: 16px;
  max-width: 200px;
  overflow-wrap: anywhere;
  word-break: break-all; }

.otherColumns {
  text-align: center !important;
  cursor: pointer;
  font-size: 16px;
  max-width: 200px;
  overflow-wrap: anywhere;
  word-break: break-all; }

.emptyColumns {
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  max-width: 200px;
  overflow-wrap: anywhere;
  word-break: break-all; }

.smallTable {
  border-left: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 5px 5px 5px !important;
  margin: 0 0 0 0 !important;
  text-align: center !important; }

.smallTableNoPointer {
  border-left: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 5px 5px 5px !important;
  margin: 0 0 0 0 !important; }

.smallTableHeader {
  background-color: #808080 !important;
  text-align: center !important;
  border-left: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 5px 5px 5px !important;
  margin: 0 0 0 0 !important;
  color: white !important; }

.buttonCell {
  border-left: 1px solid rgba(224, 224, 224, 1);
  padding:  0 0 5px 10px;
  margin: 0 0 0 0 !important; }

.smallTableIcons {
  border-left: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 5px 5px 5px !important;
  white-space: nowrap !important;
  cursor: pointer !important;

  .icons {
    margin: 0 0 0 2px !important;
    cursor: pointer !important; } }


.current {
  background-color: #c1d6fd !important;



  .accordion {

    div {
      font-size: 16px; }

    .title {
      cursor: pointer;
      font-size: 16px; }

    .versionStepper {
      border: 1px solid;
      border-radius: 5px; }

    .editingEls {
      display: grid;
      grid-template-columns: repeat(3, 115px);
      grid-auto-rows: 40px;
      grid-gap: 5px;

      p {
        height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center; } } } }
