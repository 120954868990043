@import "../../../style/library";
.mainCont {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 12px;
  width: 100%;
  top: -173px;

  @media (max-width: 1200px) {
    top: -360px;
    display: grid;
    grid-template: 1fr auto / 1fr; }


  .barsCont {
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    grid-gap: 12px;

    .bars {
      height: 160px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      background: #f8f8f8;
      border-radius: 10px;
      align-items: center;
      filter: $soft-drop-shadow;

      svg {
        circle {
          &:nth-child(1) {
            filter: $soft-drop-shadow; } } }

      .bar_title {
        margin: 10px 0 10px;
        font-weight: bold;
        font-size: 14px; }

      .progressDesc {
        width: 145px;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          font-size: 16px;
          line-height: 18px;
          font-weight: bolder;
          margin: 0; }

        .percentage {
          font-weight: bolder;
          font-size: 24px; }

        .maxSize {
          width: 100%;

          .zielwert {
            font-size: 14px;
            line-height: 16px; }

          .zielwertNum {
            font-size: 14px;
            line-height: 16px; } } } } } }

