@import "../../style/library";



.mainCont {
  padding: 0 5px 0;

  .loader {
    color: #FFFFFF;
    margin-left: 10px;
    height: 42px;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    justify-content: center;

    &.active {
      background: #43AB1B !important;
      border-radius: 5px; } }

  .percentage {
    position: absolute;
    z-index: 10001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }


  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 10px 0; }

  .textarea {
    height: 100%;
    width: 100%;
    outline: none;
    border: none; }

  .bigText {
    font-weight: 600;
    font-size: 18px;
    text-align: justify;
    margin: 0;
    color: #2B2B2B;

    g {
      cursor: pointer; }

    span {
      //background: rgb(253, 208, 208)
      cursor: pointer; }

    .whiteList {
      position: relative;
      background: rgb(44, 250, 255); } } }

.mainContEditor {
  position: relative;

  .textEditor {
    min-height: 675px;
    margin-top: 12px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.161);

    .text {
      font-size: 30px;
      font-weight: 400; } } }

.errormodalBox {
  width: 50%;
  margin: 0 auto;
  margin-top: 2%;
  max-height: 90vh;
  overflow-y: auto; }
