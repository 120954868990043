@import "../../../../style/library";
.mainCont {
  position: absolute;
  width: 670px;
  border-radius: 10px;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 49px 10px rgba(0, 0, 0, 0.28);
  outline: none;

  .head {
    border-radius: 10px 10px 0 0;

    .title {
      font-size: 20px;
      font-weight: bold; } }

  .fieldPart {
    grid-gap: 10px;

    .fieldCont {
      width: 100%;
      border-radius: 5px;
      padding: 0 0 0 8px;


      input {
        height: 100%;
        width: 100%;
        min-height: 30px;
        font-weight: 500;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 5px;
        outline: none; } } }

  .rows {
    max-height: 160px;
    overflow: auto;
    display: grid;
    grid-template: auto / 1fr;
    grid-gap: 5px;
    border: 1px solid;
    border-radius: 5px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid;

      &:last-child {
        border-bottom: 0; }

      .statusCircle {
        height: 12px;
        width: 12px;
        border-radius: 50%; }

      p {
        max-width: 355px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px; } } }

  .desc {
    border: 1px solid;
    font-size: 14px;
    border-radius: 5px; } }
