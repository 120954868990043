@import "../../../style/library";
.mainCont {
  width: 100%;

  table {
    .selectRow {
      height: 60px;

      p {
        font-size: 16px;
        line-height: 19px;
        margin: 0 auto 0 0; }

      .export {
        height: 35px;
        //width: 100px
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        font-weight: bold;
        cursor: pointer;
        width: 40px; }

      .delete {
        width: 35px;
        height: 35px;
        border-radius: 3px; } }

    .tableHead {
      min-height: 80px;

      p {
        font-size: 16px; } }

    .tableFilter {
      p {
        font-weight: bold;
        font-size: 16px; }

      .filterBtn {
        height: 42px;
        font-weight: bold; }

      input {
        border: none; }

      .order {
        width: 197px;
        height: 30px;
        border: 1px solid;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer; }

      .filter {
        width: 107px;
        font-size: 16px;
        border-radius: 20px;
        font-weight: bold; }

      .pagination {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-left: auto;

        .perPage {
          border-radius: 4px; }

        input {
          width: 38px;
          text-align: center;
          border: none; } } }

    .filterDashboard {
      font-size: 16px;

      .filterRow {
        border-bottom: 1px solid;

        p, button {
          font-size: 16px;
          border-radius: 20px;
          font-weight: bold; }

        button {
          filter: $soft-drop-shadow; } }

      .secondRow {
        .outlinedCont {
          border: 1px solid;
          border-radius: 4px; }

        .outlined {
          margin-bottom: -5px; }

        .order {
          width: 197px;
          height: 30px;
          border: 1px solid;
          font-size: 16px;
          border-radius: 5px;
          cursor: pointer; }

        input {
          height: 30px;
          //width: 124px
          border: none;
          filter: $soft-drop-shadow; }

        .minusIcon {
          margin-left: auto; } }

      .confirmation {
        button {
          filter: $soft-drop-shadow;
          border-radius: 4px;
          font-size: 16px;
          font-weight: bold; } } }


    .search {
      width: 222px;
      border: 1px solid;
      border-radius: 4px; }

    .cell {
      cursor: pointer;
      font-size: 16px; }

    .archiveName {
      cursor: pointer;
      font-size: 16px;
      max-width: 200px;
      overflow-wrap: anywhere;
      word-break: break-all;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%; } }

    .accordion {

      div {
        font-size: 16px; }

      .title {
        cursor: pointer;
        font-size: 16px; }

      .versionStepper {
        border: 1px solid;
        border-radius: 5px; } }

    .editingEls {
      display: grid;
      grid-template-columns: repeat(3, 115px);
      grid-auto-rows: 40px;
      grid-gap: 5px;

      p {
        height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center; } } } }

.folderContainer {
  max-height: 80vh;
  min-height: 70vh;
  width: 40vw;
  background: #fff;
  border-radius: 8px 8px 0 0;
  // overflow: auto
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 120px;

  .folderModal__head {
      background: #D8D8D8;
      border-radius: 8px 8px 0 0;
      padding: 0 27px;
      margin-top: -120px;
      width: 40vw; }

  .folderContent {
    // padding: 20px
    width: 15vw;
    margin-top: -100px;
    margin-left: 60px; } }

.folderBtn1 {
  margin-left: 23vw;
  // margin-top: -35px
  position: absolute;
  bottom: 20vh; }

.folderBtn2 {
  margin-left: 13vw;
  // margin-top: 100px
  position: absolute;
  bottom: 20vh; }


.container {
  height: 90vh;
  width: 90vw;
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: auto;
  padding-top: 120px; }

.exportBtn {
  float: right;
  margin-bottom: 50px;
  margin-right: 50px; }

.exportModal {
  column-count: 4;
  -moz-column-count: 4;
  -webkit-column-count: 4;

  .exportModal__head {
    background: #D8D8D8;
    border-radius: 8px 8px 0 0;
    padding: 0 27px;
    margin-top: -120px;
    width: 90vw;

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.015em;
      color: #333333; } }

  .content {
    padding: 20px; }

  .exportModal__subtitle {
    background: #E7E7E7;
    padding: 17px 27px;
    width: 90vw;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #25213B; } }

  ul {
    list-style: none; } }


.error {
  font-size: 15px;
  // margin-top: 40px
  // color: red
  width: 400px;
  margin: 0 auto; }

.modalBox {
  width: 50%;
  margin: 0 auto;
  margin-top: 20%;
  height: 300px; }
