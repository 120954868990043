@import "../../../style/library";
.mainCont {
  height: 100%;
  .headPart {
    height: 122px;
    .closeBtn {
      margin: 28px 0 0 38px; } }
  .link {
    height: 50px;
    text-decoration: none;
    border-left: 4px solid;
    p {
      font-size: 18px;
      line-height: 21px; } } }
