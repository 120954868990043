.mainCont {
  padding: 50px 40px;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px;
  }

  h2 {
    font-size: 24px;
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0 0 20px;
    border-bottom: 2px solid #707070;
  }

  .cells {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 7px 0 #bfbebe;
    padding: 15px 30px;

    .footer {
      border-top: 2px solid #e2e1e1;
      padding: 10px 0 0;

      button {
        box-shadow: 0 0 7px 0 #bfbebe;
        padding: 2px 15px;
        border-radius: 20px;
        color: #707070;
      }
    }
  }

  .row {
    border-bottom: 2px solid #707070;

    .head {
      display: grid;
      grid-template: 1fr / 1fr 1fr 1fr;
      align-items: center;
      padding: 20px 0;

      p {
        font-weight: bold;
        @media(max-width: 968px) {
          font-size: 14px;
        }
      }

      .rangerBlocks {
        display: flex;
        grid-gap: 10px;

        .rangerBlock {
          width: 30px;
          height: 15px;
          border-radius: 5px;
          @media(max-width: 968px) {
            width: 25px;
            height: 13px;
          }
          background: #e2e1e1;
        }

        .activeBlock {
          background: #415C98;
        }
      }
    }

    .desc {
      padding: 60px 20px;

      .charts {
        display: grid;
        grid-template: 1fr / 1fr 3px 1fr;
        @media(max-width: 968px) {
          grid-template: 1fr 3px .6fr / 1fr;
        }
        grid-gap: 5%;
        margin: 0 0 30px;

        .divider {
          height: 100%;
          width: 100%;
          background: #c1bebe;
        }

        .firstPart, .secondPart {
          .linearChart {
            display: flex;
            align-items: center;
            padding: 10px 0;

            p {
              width: 30%;
              color: #415C98;
              margin: 0;
            }

            .chart {
              height: 10px;
              width: 70%;
              border-radius: 6px;
              background: #e2e1e1;

              div {
                height: 100%;
                border-radius: 6px;
                background: #415C98;
              }
            }
          }
        }
      }

      .explanation {
        margin: 0;
      }
    }
  }
}
