.mainCont {
  padding: 50px 40px 0;
  display: grid;
  grid-template: 1fr / 200px 1fr;
  grid-gap: 20px;

  .overflow {
    overflow: auto;
    border-radius: 14px; }

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 5px 0; } }
