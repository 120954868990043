.mainCont {
  padding: 50px 40px 0;
  display: grid;
  grid-template: 1fr / 260px 1fr;
  grid-gap: 20px;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px 0; } }

.MoveArchiveAlertSuccess {
  cursor: pointer;
  width: 250px;

  .title, .errorTitle {
    font-weight: bold;
    display: block;
    font-size: 14px;
    margin-bottom: 8px; }

  .title {
    color: #43AB1B; }

  .errorTitle {
    color: #AB431B; }

  .bar {
    width: 90%;
    height: 6px;
    position: relative; }

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #cccccc; }

  .front, .errorFront {
    position: absolute;
    height: 100%; }

  .front {
    background-color: #43AB1B; }

  .errorFront {
    background-color: #AB431B; } }
