@import "../../style/library";
.mainCont {
  width: 100%;
  margin-top: 15px;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  grid-template-areas: "license result select";
  grid-gap: 3%;
  align-items: center;
  //height: 50px
  font-size: 16px;
  line-height: 18px;
  filter: none;
  padding: 0 0 50px;

  @media (max-width: 1025px) {
    grid-template: 1fr 1fr / 1fr 1fr;
    align-items: start;
    grid-template-areas: "license result" "select select"; }

  .license {
    min-width: 190px;
    grid-area: license; }

  .results {
    justify-content: space-around;
    grid-area: result;
    display: flex;
    grid-gap: 10px;

    .size {
      font-size: 12px;
      line-height: 14px; } }

  .selects {
    grid-area: select;

    .dropLanguage {
      position: relative;
      height: 20px;
      width: 300px;
      cursor: pointer;

      .selectField {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #e2e1e1;
        background: #f8f8f8;

        .icon {
          transition: .1s ease-in; }

        .iconReversed {
          transform: rotate(180deg); } }


      .select {
        background: #fff;
        box-shadow: 0 0 5px 3px #dedede;
        padding: 5px;
        display: flex;
        flex-direction: column-reverse;
        grid-gap: 4px; }

      ul {
        list-style: none;
        padding: 0;

        li {
          width: 190px;
          margin: 0 0 0 auto;

          &:hover {
            font-weight: bold; } } } }


    .dropTextType {
      position: relative;
      height: 20px;
      width: 200px;
      cursor: pointer;

      .selectField {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #e2e1e1;
        background: #f8f8f8;

        .icon {
          transition: .1s ease-in; }

        .iconReversed {
          transform: rotate(180deg); } }


      .select {
        background: #fff;
        box-shadow: 0 0 5px 3px #dedede;
        padding: 5px;
        display: flex;
        flex-direction: column-reverse;
        grid-gap: 4px; }

      ul {
        list-style: none;
        padding: 0;

        li {
          width: 190px;
          margin: 0 0 0 auto;

          &:hover {
            font-weight: bold; } } } } } }



.textModal {
  max-height: 90vh;
  width: 100%;
  max-width: 70vw;
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  white-space: pre-line;
  overflow: auto; }
