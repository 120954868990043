@import "../../style/library";
.mainCont {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .form {
    position: relative;
    width: 803px;
    height: 755px;
    margin-top: 127px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: .3s ease-in-out;

    .blueRec {
      width: 370px;
      height: 205px;
      position: absolute;
      top: -117px;
      left: 50%;
      transform: translateX(-50%);
      background: no-repeat url("assets/square728.png") center / contain;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        margin: 38px 0 0;
        height: 79px; }

      .text {
        margin: auto 0 15px; } }

    .fields {
      display: flex;
      flex-direction: column;
      margin: 203px 128px 0;
      height: 450px;

      .password {
        margin: 9px 0 0;
        padding: 0 0 9px;
        text-align: end;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer; }

      .fieldsActions {
        .formControls {
          margin: 50px 0 9px;
          display: grid;
          grid-template: 1fr / 1fr 1fr;
          grid-column-gap: 10px;
          padding: 0 0 17px;

          .formControl {
            display: flex;
            align-items: center;

            .name {
              margin-right: 15px;
              font-weight: bold; } } }

        .news {
          margin: 50px 0 0;
          padding: 10px;
          border-top: 1px solid;

          p {
            line-height: 19px; } }

        .checkboxCont {
          display: flex;

          .checkboxIcon {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0; }

            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 18px;
              width: 18px;
              border: 1px solid;
              border-radius: 3px;

              div {
                position: absolute;
                display: none;
                left: 4px;
                transition: .2s ease;
                top: 1px;
                width: 8px;
                height: 10px;
                border: solid;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg); } }

            input:checked + .checkmark {
              div {
                display: block; } } } } } }

    .submitCont {
      display: flex;
      justify-content: center;

      .submit {
        margin-left: 5px;
        margin-right: 5px;
        height: 45px;
        width: 186px;
        background: #3EAA1B;
        text-align: start;
        padding: 6px;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
        color: white; }

      .submitBack {
        margin-left: 5px;
        margin-right: 5px;
        height: 45px;
        width: 186px;
        background: #b9b9ba;
        text-align: center;
        padding: 6px;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
        color: white;

        img {
          width: 33px;
          height: 33px;
          object-fit: contain;
          margin-right: 16px; } }

      .submitLDAP {
        margin-left: 5px;
        margin-right: 5px;
        height: 45px;
        width: 186px;
        background: #194978;
        text-align: start;
        padding: 6px;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 500;
        line-height: 23px;
        color: white;

        img {
          width: 33px;
          height: 33px;
          object-fit: contain;
          margin-right: 16px; } } } } }
