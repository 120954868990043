@import "../../../../style/library";

.dnd {
  width: 100%;
  background-color: #fef8dd;
  color: #a59f84;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  border-style: solid;
  border-color: gold;
  width: 90%;

  .dndText {
    color: #a59f84;
    margin: auto;
    cursor: pointer;
    width: 200px;
    text-align: center;
    font-weight: bold;
    font-size: 16px !important; } }

.mainCont {
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto; }

  .mainTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 55px;
    margin: 69px 0 102px; }

  table {
    .tableHead {
      min-height: 80px;

      p {
        font-size: 16px; } }

    .tableFilter {
      .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-left: auto;

        input {
          width: 38px;
          text-align: center;
          border: none; } }

      .perPage {
        border-radius: 4px; }

      p {
        font-size: 16px; }

      .filterBtn {
        height: 42px;
        font-weight: bold; }

      .search {
        width: 222px;
        border: 1px solid;
        border-radius: 4px; } }

    .filterDashboard {
      font-size: 16px;

      .filterRow {
        border-bottom: 1px solid;

        p, button {
          font-size: 16px;
          border-radius: 20px;
          font-weight: bold; }

        button {
          filter: $soft-drop-shadow; } }

      .secondRow {
        border-bottom: 1px solid;

        .order {
          width: 197px;
          height: 30px;
          border: 1px solid;
          font-size: 16px;
          border-radius: 5px;
          cursor: pointer; }

        input {
          height: 30px;
          width: 124px;
          border: none;
          filter: $soft-drop-shadow; }

        .minusIcon {
          margin-left: auto; } }

      .confirmation {
        button {
          filter: $soft-drop-shadow;
          border-radius: 17px;
          font-size: 16px;
          font-weight: bold; } } }

    .search {
      width: 220px; }

    .cell {
      font-size: 16px;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%; }

      div {
        font-size: 16px; }

      .title {
        font-size: 16px; }

      .versionStepper {
        border: 1px solid;
        border-radius: 5px; } }

    .row {

      .activeBlock {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        margin-left: auto; }

      .circle {
        width: 10px;
        height: 10px;
        margin: 0 40px 0 auto; }

      .active {
        background: #3CC13B;
        filter: drop-shadow(0px 0px 2px rgba(19, 255, 17, 0.5)); }

      .inactive {
        background: #7D7D7D;
        filter: drop-shadow(0px 0px 2px #979797); }

      .iconBtn {
        width: 35px;
        height: 35px;
        border-radius: 3px; } }

    .accordion {
      .userData {
        width: 70%;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 17px 0;

        div {
          height: 50%;
          border: 1px solid rgb(168, 181, 255); }

        li {
          padding: 0;

          .title {
            width: 140px;
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            cursor: pointer; }

          .text {
            font-size: 14px;
            line-height: 16px; } } }

      .resetPasswordBtn {
        width: 180px;
        height: 36px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold; } }

    .addNewCell {
      display: grid;
      grid-template: auto / 1fr 1fr;
      grid-gap: 10px; }

    .selectRow {
      height: 60px;

      p {
        font-size: 16px;
        line-height: 19px; }

      .export {
        height: 35px;
        width: 100px;
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        font-weight: bold;
        margin-left: auto; }

      .delete {
        width: 35px;
        height: 35px;
        border-radius: 3px;
        margin: 0 0 0 auto; } } }

  .customDatePicker {
    background: rgba(0, 0, 0, 0.09);
    height: 56px;
    padding: 6px 16px;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid; } }

.termModal {
  color: #585858;
  width: 35vw;
  background: #fff;
  padding: 20px;
  border-radius: 14px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;

  .careful {
    color: #f50057; }

  .info {
    padding: 0;
    color: #585858;
    height: 16px;
    margin-bottom: 6px; }

  h2 {
    font-size: 32px;
    color: #707070;
    margin: 0 0 20px 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #ccc; }

  .partTitle {
    font-size: 18px;
    color: #707070;
    font-weight: bold; } }

.alignItem {
  justify-content: center; }

.uploadBtn {
  width: 300px;
  position: relative;
  height: 80px; }

.rightBox {
  margin-top: 5px;
  width: 300px;
  background-color: #FDE992;
  border: 15px none gray;
  border-radius: 15px;
  position: relative;
  right: 10; }
