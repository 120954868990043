.mainCont {
  min-width: 221px;

  .breadcrumb {
    font-weight: bold;
    //font-size: 18px
    //line-height: 21px
    margin-bottom: 119px; }


  ul {
    list-style: none;
    padding: 0;

    li {

      .link {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;

        p {
          display: flex;
          align-items: center;
          width: 240px;
          height: 48px;
          padding-left: 31px; } }

      .activeLink {

        p {
          background: #C5DCFA;
          border-radius: 12px; } } } } }


