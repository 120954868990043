//primary color variables
$color-primary-0: #FFFFFF;
$color-primary-50: #E2E2E2;
$color-primary-100: #b7b7b7;
$color-primary-200: #b2b2b2;
$color-primary-300: #9a9797;
$color-primary-400: #808080;
$color-primary-500: #707070;
$color-primary-700: #363636;
$color-primary-800: #2b2b2b;
$color-primary-1000: #000000;

//special colors
$color-active-blue: #415C98;

//status color variables
$color-status-error: #BF1A1A;
$color-status-true: #3EAA1B;

//primary background variables
$bg-primary-0: #ffffff;
$bg-primary-100: #F0F0F0;
$bg-primary-300: #a0a0a0;
$bg-primary-700: #585858;

//drop-shadow variables
$soft-drop-shadow: drop-shadow(0 0 3px rgba(0, 0, 0, 0.161));
$middle-drop-shadow: drop-shadow(0 0 10px rgba(0, 0, 0, 0.302));
$strong-drop-shadow: drop-shadow(0 0 5px rgba(0, 0, 0, 0.404));

//progress color variables
$progress-20: #DD0D08;
$progress-40: #E46D19;
$progress-60: #EACD2A;
$progress-80: #A1BE23;
$progress-100: #43AB1B;
