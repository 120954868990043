.title {
  height: 56px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 0 27px;
  margin: 0; }

.subTitle {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 27px; }

.yellowField {
  position: absolute !important;
  top: 20px;
  left: 27px; }

.redField {
  position: absolute !important;
  top: 20px;
  right: 27px; }

.greenField {
  position: absolute !important;
  bottom: 20px;
  right: 27px; }

.blueField {
  position: absolute !important;
  bottom: 20px;
  left: 27px; }

.circle {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  .yellowFr {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100% 0 0 0;
    overflow: hidden;

    div {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #ff9800;
      border-radius: 100% 0 0 0; } }


  .redFr {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0 100% 0 0;
    overflow: hidden;

    div {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #f44336;
      border-radius: 0 100% 0 0; } }


  .greenFr {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0 0 100% 0;
    overflow: hidden;

    div {
      position: absolute;
      top: 0;
      left: 0;
      background: #1b5e20;
      border-radius: 0 0 100% 0; } }


  .blueFr {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 100%;
    overflow: hidden;

    div {
      position: absolute;
      top: 0;
      right: 0;
      background: #0d47a1;
      border-radius: 0 0 0 100%; } } }



