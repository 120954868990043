@import "../../../style/library";
.mainCont {
  //transform: translateY(-173px)
  min-height: 895px;
  max-width: 467px;
  margin-left: 12px;
  filter: $soft-drop-shadow;

  .onDesktop {
    display: block; }

  .drawerToggler {
    height: calc(100% - 117px);
    display: none;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
    transition: .05s ease-in;
    @media(max-width: 1025px) {
      height: calc(100% - 145px);
      @media(max-width: 991px) {
        height: calc(100% - 153px); } }

    &:hover {
      background: #d9d9d9; } }

  @media (max-width: 1495px) {
    //.onDesktop
    //  display: none

    .drawerToggler {
      display: flex; } } }

