@import "../../../style/library";

.mainCont {
  padding: 50px 40px;

  .filterRow {
    width: 100%;
    box-shadow: 0 0 7px 0 #bfbebe;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .nameRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 90%;
      color: #707070;
      fill: #707070;
      box-shadow: 0 0 7px 0 #bfbebe;
      border-radius: 20px;
      cursor: pointer;
    }

    .delRow {
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 10%;
      color: #707070;
      fill: #707070;
      box-shadow: 0 0 7px 0 #bfbebe;
      border-radius: 20px;
      cursor: pointer;
    }

    .textWrap {
      width: 100%;
      border-radius: 5px;
      margin: 0;
      justify-content: center;
      text-align: center;
      align-self: center;
      padding: 6px 7px;
    }
    .text {
      padding: 1px 7px;
      margin: 0;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px;
  }

  .withBorder {
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
  }
  h2 {
    font-size: 24px;
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
  }

  .cells {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 7px 0 #bfbebe;
    padding: 15px 30px;
    border-radius: 10px;

    .footer {
      border-top: 2px solid #e2e1e1;
      padding: 10px 0 0;

      button {
        box-shadow: 0 0 7px 0 #bfbebe;
        padding: 2px 15px;
        border-radius: 20px;
        color: #707070;
      }
    }
  }

  .cell_two_grid {
    @extend .cells;
    grid-column-start: 1;
    grid-column-end: 3;

  }

  .speechClimate {
    width: 100%;

    .titles {
      p {
        margin: 0;
      }
    }

    .flexEnd {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .circle {
      display: grid;
      grid-template: 1fr 1fr / 1fr 1fr;
      grid-gap: 5px;
      width: 100%;
      height: 300px;

      .red, .yellow, .blue, .green {
        height: 100%;
        width: 100%;
        display: flex;

        .transparent {
          display: flex;

          .cell {
            height: 100%;
            width: 100%;
          }
        }
      }

      .red {
        .transparent {
          height: 100px;
          width: 100px;
          align-self: flex-end;
          align-items: flex-end;
          border-radius: 0 100% 0 0;
          background: rgba(255, 0, 0, 0.5);

          .cell {
            background: rgb(167, 3, 3);
            border-radius: 0 100% 0 0;
            height: 50%;
            width: 50%;
          }
        }
      }

      .yellow {
        justify-content: flex-end;

        .transparent {
          height: 60px;
          width: 60px;
          align-self: flex-end;
          align-items: flex-end;
          justify-content: flex-end;
          border-radius: 100% 0 0 0;
          background: rgba(255, 142, 0, 0.5);

          .cell {
            background: rgb(255, 143, 0);
            border-radius: 100% 0 0 0;
            height: 80%;
            width: 80%;
          }
        }
      }

      .blue {
        justify-content: flex-end;

        .transparent {
          height: 150px;
          width: 150px;
          justify-content: flex-end;
          border-radius: 0 0 0 100%;
          background: rgba(65, 92, 152, 0.5);

          .cell {
            background: rgb(65, 92, 152);
            border-radius: 0 0 0 100%;
            height: 80%;
            width: 80%;
          }
        }
      }

      .green {

        .transparent {
          height: 120px;
          width: 120px;
          border-radius: 0 0 100% 0;
          background: rgba(62, 170, 27, .5);

          .cell {
            background: rgb(62, 170, 27);
            border-radius: 0 0 100% 0;
            height: 70%;
            width: 70%;
          }
        }
      }
    }
  }

  .HIXCLIX {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    @media(max-width: 1268px) {
      grid-template: 1fr 1fr / 1fr;
    }
  }

  .SpeechTonality {
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr 2.3fr;
    grid-gap: 30px;
    @media(max-width: 1168px) {
      grid-template: 1fr 1fr / 1fr;
    }
  }

  .CSpeechTonality {
    width: 100%;
    display: grid;
    grid-template: auto auto / 1fr;
    grid-gap: 30px;
  }

  .tonality {
    width: 100%;

    h3 {
      font-size: 22px;
      margin: 0 0 20px;
    }
  }

  .words {
    margin: 30px 0 0;

    .words_main {
      display: grid;
      grid-template: 1fr / 1fr 1fr;

      @media(max-width: 1268px) {
        grid-template: 1fr auto / 1fr;
        grid-gap: 50px;

        .vocabulary {
          border-right: none !important;
          padding: 0 !important;
          margin: 0 0 0 0 !important;
        }
      }

      .vocabulary {
        border-right: 3px solid #e2e1e1;
        padding: 0 30px 0 0;
        margin: 0 0 10px 0;
      }

      .distribution {
        width: 100%;
        padding: 2px 0 0 30px;
      }
    }
  }
}

.textTitles{
  font-weight: bold;
  font-size: 16px;
  grid-gap: 10px;
  margin: 0 auto 0 0;
}

.tonRanger {
  width: 100%;
  display: grid;
  border-top: 2px solid #e2e1e1;

  .tonRangerRow {
    display: grid;
    grid-template: 1fr / repeat(3, 1fr);
    align-items: center;
    grid-gap: 10px;
    padding: 10px 0;

    p {
      font-weight: bold;
      font-size: 20px;
      margin: 0;
      @media(max-width: 968px) {
        font-size: 14px;
      }
    }

    .rangerBlocks {
      display: flex;
      grid-gap: 10px;

      .rangerBlock {
        width: 30px;
        height: 15px;
        display: flex;

        div{
          height: 100%;
          width: 100%;
        }
        @media(max-width: 968px) {
          width: 25px;
          height: 13px;
        }
        background: #e2e1e1;
      }
    }

    .text2 {
      text-align: end;
      justify-self: flex-end;
    }
  }
}

.withoutBorder {
  border: none !important;
}
.distribution_main {
  display: grid;
  grid-template: 40px 200px / 1fr;

  .distribution_head {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .colorBlock {
      width: 20px;
      height: 20px;
    }

    p {
      margin: 0 0 0 10px;
    }
  }

  .dist_cols {
    display: flex;
    grid-gap: 15px;

    .dist_col1 {
      width: 30%;
      border-right: 2px solid #e2e1e1;
      padding: 0 15px 0 0;
    }

    .dist_col2 {
      width: 70%;
      //border-right: 2px solid #e2e1e1;
      padding: 0 15px 0 0;
    }

    .dist_col {
      display: grid;
      grid-template: 50px 1fr / 1fr;

      h4 {
        font-size: 13px;
      }
    }
  }

  .distDesc {
    margin: 20px 0 0;
    font-size: 14px;
    line-height: 14px;
  }
}
