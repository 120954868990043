@import "../../../../style/library";
.mainCont {
  position: absolute;
  width: 670px;
  border-radius: 10px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 49px 10px rgba(0, 0, 0, 0.28);
  outline: none;

  .fieldCont {
    display: flex;
    width: auto;
    margin: 0 0 0 0;
    padding: 0 0 0 0;

    .imgdiv {
      padding-top: 20px;
      padding-bottom: 30px;
      margin-bottom: auto !important;
      margin-top: auto !important;

      .image {
        height: 20px; } } }

  .load {
    max-width: 355px;
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    color: #0d47a1;
    cursor: pointer;
    line-height: 16px; }

  .head {
    border-radius: 10px 10px 0 0;

    .title {
      font-size: 20px;
      font-weight: bold; } }

  .fieldPart {
    grid-gap: 10px;

    input {
      width: 100%;
      min-height: 30px;
      font-weight: 500;
      font-size: 16px;
      border: none;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 5px;
      outline: none; } }

  .android {
    color: #808080;
    font-size: 30px;
    margin-bottom: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg); }
      100% {
        transform: rotate(360deg); } } }

  .rows {
    max-height: 315px;
    overflow: auto;
    display: grid;
    grid-template: auto / 1fr;
    grid-gap: 5px;
    border: 1px solid;
    border-radius: 5px;



    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid;

      .textArea {
        line-height: 1.0em;
        width: 450px;
        min-height: 80px;
        border: 1px solid lightgray;
        outline: none;
        padding: 10px;
        margin: 2px;
        margin-top: 10px;
        margin-bottom: -15px;
        border-radius: 15px; }

      &:last-child {
        border-bottom: 0; }

      p {
        max-width: 355px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px; } } }

  .errorText {
    font-size: 14px; }

  .errorDescTitle {
    font-size: 16px;
    font-weight: 700; }

  .desc {
    border: 1px solid;
    font-size: 14px;
    border-radius: 5px; } }
