.mainCont {
  padding: 50px 40px;
  border-radius: 10px;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px;
  }

  h2 {
    font-size: 24px;
    padding: 0 0 15px;
    color: #808080;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
  }

  .cells {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 7px 0 #bfbebe;
    background: #fff;
    padding: 15px 30px;
    border-radius: 10px;

    .footer {
      border-top: 2px solid #e2e1e1;
      padding: 10px 0 0;

      button {
        box-shadow: 0 0 7px 0 #bfbebe;
        padding: 2px 15px;
        border-radius: 20px;
        color: #707070;
      }
    }
  }

  .distribution {
    padding: 15px 30px;

    .distribution_main {
      display: grid;
      grid-template: 200px / 2fr 1.5fr;
      grid-gap: 50px;
      margin-bottom: 20px;
      padding: 0 0 30px;
      border-bottom: 3px solid #e2e1e1;


      .distribution_head {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .colorBlock {
          width: 15px;
          height: 15px;
        }

        p {
          margin: 0 0 0 10px;
          font-size: 14px;
        }
      }

      .dist_cols {
        height: 170px;
        display: flex;
        grid-gap: 15px;

        .dist_col1 {
          width: 30%;
          border-right: 2px solid #e2e1e1;
          padding: 0 15px 0 0;
        }

        .dist_col2 {
          width: 70%;
          padding: 0 15px 0 0;
        }

        .dist_col {
          display: grid;
          grid-template: 50px 1fr / 1fr;

          h4 {
            font-size: 16px;
          }
        }
      }

      .distDesc {
        margin: 20px 0 0;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .vocabulary {
    margin: 30px 0 0 0;
    padding: 15px 30px;
    background: #fff;
    box-shadow: 0 0 7px 0 #bfbebe;
  }
}
