@import "../../../style/library";
.mainCont {
  width: 260px;

  button {
    font-size: 16px;
    height: 35px;
    font-weight: bold;
    border-radius: 6px; }

  .archiveBtns {
    height: 48px;
    min-width: 240px;
    font-size: 18px;
    line-height: 21px;
    text-align: start; }

  .archiveWithFilter {
    filter: $soft-drop-shadow; }

  .breadcrumb {
    width: 100px; }

  .directories {
    ul {
      list-style: none;

      li {
        &:first-child {
          border: none; }
        border-top: 1px solid;
        cursor: pointer;
        font-weight: bold;

        .addFolder {
          border-radius: 8px;
          cursor: pointer; }

        p {
          margin: 0 auto 0 10px; } } } }


  .diskSpace {
    margin: 30px 0 0;

    a {
      color: #777;
      margin: 5px 0 0;
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        margin: 0 0 0 10px; } }


    .wrapper {
      width: 100%;
      background: #cccccc;
      border-radius: 5px;

      div {
        height: 5px;
        width: 3%;
        border-radius: 5px;
        background: #415C98; } } } }

.error {
  width: 400px;
  margin: 0 auto; }
