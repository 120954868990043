.mainCont {
  width: 100%;
  padding: 50px 40px;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px;
  }

  .withBorder {
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;
  }

  h2 {
    font-size: 24px;
    padding: 0 0 15px;
    border-bottom: 2px solid #e2e1e1;
    margin: 0 0 30px;

    &.withoutBorder {
      border: none;
    }
  }

  .cells {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 7px 0 #bfbebe;
    padding: 15px 30px;
    border-radius: 10px;

    .footer {
      border-top: 2px solid #e2e1e1;
      padding: 10px 0 0;

      button {
        box-shadow: 0 0 7px 0 #bfbebe;
        padding: 2px 15px;
        border-radius: 20px;
        color: #707070;
      }
    }
  }

  .rangeNumsCont{
    padding: 0 150px;
    p{
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 5px;
      color: #505050;
    }
  }

  .RSCLTCHCLines{
    border-top: 2px solid #e2e1e1;

    h3{
      font-size: 20px;
      font-weight: bold;
    }

    h4{
      width: 150px;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    p{
      font-size: 14px;
      margin: 0;
      white-space: pre-line;
    }

    .chart{
      position: relative;
      width: calc(100% - 300px);
      background: #e2e1e1;
      border-radius: 10px;
      height: 10px;

      .filled{
        position: absolute;
        height: 100%;
        border-radius: 10px;
      }

      .indicator{
        position: absolute;
        background: #b3b3b3;
        height: 100%;
        width: 2px;
      }
    }
  }

  .tagCont {
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    @media(max-width: 968px) {
      grid-template: 1fr 1fr / 1fr;
      padding: 0;
    }
    grid-gap: 30px;
    margin: 0 0 30px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e2e1e1;
    }

    .mainTagCloud {
      position: relative;
      height: 300px;
      width: 100%;
      max-width: 1000px;

      .appOuter {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 1000px;
        max-height: 260px;
        width: 100%;

        .appInner {
          display: flex;
          flex-direction: column;
          height: 100%;
          max-width: 1000px;
          width: 100%;

          .tagCloud {
            flex: 1;

            div {
              transition: 1.4s;
            }
          }
        }
      }
    }
  }
}

.textTitles{
  font-weight: bold;
  font-size: 16px;
  grid-gap: 10px;
}

