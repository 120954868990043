@import "../../../style/library";
.mainCont {
  width: 100%;

  table {
    .tableHead {
      min-height: 80px;

      p {
        font-size: 16px; } }

    .tableFilter {
      .pagination {
        width: 400px;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-left: auto;

        input {
          width: 38px;
          text-align: center;
          border: none; } }

      .perPage {
        //width: 200px
        border-radius: 4px; }

      p {
        font-size: 16px; }

      .filterBtn {
        height: 42px;
        font-weight: bold; }

      .search {
        width: 222px;
        border: 1px solid;
        border-radius: 4px; }

      .grantLicenses {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: 0 0 0 auto; } }

    .filterDashboard {
      font-size: 16px;

      .filterRow {
        border-bottom: 1px solid;

        p, button {
          font-size: 16px;
          border-radius: 20px;
          font-weight: bold; }

        button {
          filter: $soft-drop-shadow; } }

      .secondRow {
        border-bottom: 1px solid;

        .order {
          width: 197px;
          height: 30px;
          border: 1px solid;
          font-size: 16px;
          border-radius: 5px;
          cursor: pointer; }

        input {
          height: 30px;
          width: 124px;
          border: none;
          filter: $soft-drop-shadow; }

        .minusIcon {
          margin-left: auto; } }

      .confirmation {
        button {
          filter: $soft-drop-shadow;
          border-radius: 17px;
          font-size: 16px;
          font-weight: bold; } } }


    .search {
      width: 220px; }

    .cell {
      font-size: 16px;

      .title {
        font-size: 16px; }

      .bigCell {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.38);
        box-sizing: border-box;
        border-radius: 6px;
        padding: 10px 56px 20px 23px; } }

    .row {

      .activeBlock {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        margin-left: auto; }

      .circle {
        width: 10px;
        height: 10px;
        margin: 0 40px 0 auto; }

      .active {
        background: #3CC13B;
        filter: drop-shadow(0px 0px 2px rgba(19, 255, 17, 0.5)); }

      .inactive {
        background: #7D7D7D;
        filter: drop-shadow(0px 0px 2px #979797); } }

    .accordion {
      .userData {
        width: 70%;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 17px 0;

        div {
          height: 50%;
          border: 1px solid rgb(168, 181, 255); }

        li {
          padding: 0;

          .title {
            width: 140px;
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            cursor: pointer; }

          .text {
            font-size: 14px;
            line-height: 16px; } } }

      .resetPasswordBtn {
        width: 180px;
        height: 36px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold; } }

    .selectRow {
      height: 60px;

      p {
        font-size: 16px;
        line-height: 19px; }

      .export {
        height: 35px;
        width: 100px;
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        font-weight: bold;
        margin-left: auto; }

      .delete {
        width: 35px;
        height: 35px;
        border-radius: 3px; } } }

  .customDatePicker {
    background: rgba(0, 0, 0, 0.09);
    height: 56px;
    padding: 6px 16px;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid; } }

.borderBottom {
  border-radius: 0 0 6px 6px; }

.borderTop {
  border-radius: 6px 6px 0 0; }
