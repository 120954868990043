.main_title {
  font-weight: bold;
  font-size: 48px;
  line-height: 55px; }

.subTitle {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 27px;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px; } }

.saveChangesBtn {
  display: flex;
  justify-content: flex-end; }

.mainCont {
  border-radius: 6px;
  padding: 50px 40px 0;

  @media (min-width: 1200px) {
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 70px 0; }

  .title {
    height: 56px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding: 0 27px;
    margin: 0; }

  .featuresSwitchs {
    padding: 0 20px 10px; }

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    padding: 0 30px 10px;

    li {
      display: flex;
      align-items: center;
      padding: 10px 0;

      .title {
        width: 221px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400; }

      ul {
        display: flex;
        flex-direction: column;
        align-self: start; } } } }

.borderBottom {
  border-radius: 0 0 6px 6px; }

.benchmarks {
  .header {
    height: 63px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  ul {
    li {
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%; } } }

  .benchmarks_btn {
    margin: 0 0 0 9px;
    font-weight: bold; } }

.languages {
  .backDrop {
    top: 0;
    bottom: 7px;
    left: 0;
    right: 0;
    z-index: 99999999;
    margin-left: 135px;
    border-radius: 9px;
    opacity: .5; } }

.corporateLanguageUl {
  display: grid;
  grid-template-columns: 1fr !important;

  .fields {
    display: grid;
    grid-template-columns: 35% 1fr;
    grid-column-gap: 10px; }

  .langSwitch {
    top: -25px;
    right: 0; } }

.select_options {
  font-size: 12px; }

.borderBottom {
  border-radius: 0 0 6px 6px; }

.borderTop {
  border-radius: 6px 6px 0 0; }

.groupBackDrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .15);
  border-radius: 3px; }
