@import "../../../../../style/library";
.mainCont {
  list-style: none;
  width: 100%;
  padding: 0;

  .row {
    border-radius: 5px;
    border-top: 1px dotted #e2e1e1;

    .head {

      .circle {
        height: 15px;
        width: 15px;
        border-radius: 50%; }

      .title {
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        color: #000; } }

    .modalWin {
      display: none;

      .head {
        border-bottom: 1px dotted #e2e1e1;
        margin: 0 0 20px; }

      .ranger {
        position: relative;
        background: #e2e1e1;
        border-radius: 20px;

        div {
          border-radius: 20px;
          height: 10px; }

        .triangle {
          position: absolute;
          bottom: -7px;

          div {
            border-radius: 20px 0 0 20px;
            height: 10px; } } }

      .desc {
        font-size: 14px;
        line-height: 16px; } } }

  .recShapedRangerRow {
    border-radius: 5px;
    border-top: 1px dotted #e2e1e1;

    .circle {
      width: 15px;
      height: 15px;
      border-radius: 50%; }

    .title {
      font-size: 18px;
      font-weight: bold;
      cursor: pointer; }

    .modalWin {
      display: none;

      .desc {
        font-size: 14px;
        line-height: 16px; }

      .rangerRecShaped {
        position: relative;
        background: #e2e1e1;
        border-radius: 20px;
        display: grid;
        grid-template: 1fr / repeat(10, 1fr);

        .center {
          position: absolute;
          height: 100%;
          width: 5px;
          border-radius: 1%;
          background: #8f8f8f;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }

        div {
          width: 100%;
          height: 10px;
          border-right: 1px solid #e2e1e1; }

        div:nth-child(1) {
          border-radius: 5px 0 0 5px; }

        div:nth-child(10) {
          border-radius: 0 5px 5px 0;
          border-right: none; } } } }

  .open {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.404));

    .modalWin {
      display: block; } } }
